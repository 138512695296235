@use "sass:math";
@import "mixins";

:root {
  // Fonts
  --font-family: Poppins;
  --font-size: 16px;

  // Colors
  --main-dark: #1c2743;
  --main-dark-hovered: #2c3859;
  --text-color: #495269;
  --bg-color: #f5fcff;
  --accent: #02ffb3;
  --accent-second: #18b9cf;
  --accent-gradient: linear-gradient(
    45deg,
    var(--accent),
    var(--accent-second)
  );
  --green: #11b26d;
  --gray: #8d93a1;
  --overlay-color: rgba(118, 118, 118, 0.5);

  // Z-index
  --burger-z-index: 10;
  --modal-z-index: 140;
}

$min-width: 350;
$max-width: 1920;
$max-width-container: 1180;
$container-padding: 40;
$container-width: $max-width-container + $container-padding;
$pc: 1220px;
$tablet: em(991.98);
$mobile: 767px;
$mobileSmall: em(479.98);

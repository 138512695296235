@import "src/assets/styles/variables";

.Header {
  border-bottom: 1px solid rgba(52, 81, 123, 0.1);
  padding: 15px 0;
  background-color: var(--bg-color);
  @media (max-width: $mobile) {
    padding: 15px 0;
    &::after {
      content: "";
      position: fixed;
      width: 100vw;
      height: 100%;
      z-index: 9;
      top: 0;
      right: 0;
      background: rgba(206, 206, 206, 0.5);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease 0s;
    }
    &.menuOpen {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.body {
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #3a4c61;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  img {
    flex: 0 0 42px;
    height: 42px;
    width: 42px;
    max-width: 100%;
  }
}

.hideOnMobile {
  display: none;
  @media (max-width: $mobile) {
    display: block;
  }
}

.menu {
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 270px;
    padding-top: 50px;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    z-index: var(--burger-z-index);
    transform: translate(-100%, 0px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease 0s;
    .menuOpen & {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px);
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.menuList {
  display: flex;
  column-gap: 30px;
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.link {
  font-weight: 500;
  transition: color 0.2s ease 0s;
  @media (any-hover: hover) {
    &:hover {
      color: var(--main-dark);
    }
  }
  @media (max-width: $mobile) {
    padding: 20px 0;
    text-align: center;
    font-size: 21px;
  }
}

.burgerButton {
  display: none;
  @media (max-width: $mobile) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: var(--main-dark);
    position: relative;
    z-index: var(--burger-z-index);
  }
}

@use "sass:math";
@import "src/assets/styles/variables";

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  min-width: 160px;
  height: 44px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  outline-offset: 4px;
  padding: 10px 20px;
  transition: all 0.2s ease 0s;
  transition-property: background-position, color, background-color, opacity;
  &.full-width {
    width: 100%;
    display: flex;
    font-size: 16px;
    svg {
      flex: 0 0 20px;
      font-size: 20px;
    }
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
  svg {
    flex: 0 0 13px;
    font-size: 13px;
  }
  &.full-width-mobile {
    @media (max-width: $mobileSmall) {
      width: 100%;
      display: flex;
    }
  }
}

.primary {
  color: #fff;
  background-color: var(--main-dark);
  @media (any-hover: hover) {
    &:hover {
      background-color: var(--main-dark-hovered);
    }
  }
}

.primary-green {
  color: #fff;
  background: var(--accent-gradient);
  background-size: 150% 150%;
  background-position: 0 0;
  @media (any-hover: hover) {
    &:hover {
      background-position: 100% 100%;
    }
  }
}

.outline {
  color: var(--main-dark);
  border: 2px solid var(--main-dark);
  transition: box-shadow 0.2s ease 0s;
  font-size: 16px;
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 3px 10px rgba(28, 39, 67, 0.3);
    }
  }
}

.outline-green {
  background-color: #fff;
  position: relative;
  color: var(--main-dark);
  transition: box-shadow 0.2s ease 0s;
  font-size: 16px;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 2px;
    background: var(--accent-gradient);
    -webkit-mask: var(--accent-gradient) content-box, var(--accent-gradient);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0 3px 10px rgba(2, 255, 179, 0.36);
    }
  }
}

.clear {
  min-width: auto;
  height: auto;
  color: #656973;
  font-size: 16px;
  padding: 0;
  svg {
    font-size: 16px;
    flex: 0 0 16px;
    color: var(--main-dark);
  }
  @media (any-hover: hover) {
    &:hover {
      color: var(--main-dark);
    }
  }
}

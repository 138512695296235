@use "sass:math";

@function rem($px) {
  $result: math.div($px, 16) + rem;
  @return $result;
}

@function em($px, $current: 16) {
  $result: math.div($px, $current) + em;
  @return $result;
}

@mixin adaptiveValue(
  $property,
  $startSize,
  $minSize,
  $keepSize: 0,
  $widthFrom: $container-width,
  $widthTo: $min-width
) {
  @if ($startSize==0) {
    $startSize: 0.000001;
  }
  @if ($minSize==0) {
    $minSize: 0.000001;
  }

  $addSize: math.div($startSize - $minSize, 16);

  @if ($widthFrom == $container-width and $max-width-container == 0) {
    $widthFrom: $max-width;
  }

  $widthFromMedia: em($widthFrom);
  $widthToMedia: em($widthTo);

  $slope: math.div(($startSize - $minSize), ($widthFrom - $widthTo));
  $yIntersection: -$widthTo * $slope + $minSize;
  @if ($yIntersection==0) {
    $yIntersection: 0.000001;
  }
  $flyValue: #{rem($yIntersection)}" + " #{$slope * 100}vw;

  $propertyValue: #{"clamp(" rem($minSize) "," $flyValue "," rem($startSize) ")"};
  @if ($minSize > $startSize) {
    $propertyValue: #{"clamp("
      rem($startSize)
      ","
      $flyValue
      ","
      rem($minSize)
      ")"};
  }

  @if $keepSize != 1 and $keepSize != 3 {
    @media (min-width: $widthFromMedia) {
      #{$property}: rem($startSize);
    }
  }
  @media (min-width: $widthToMedia) and (max-width: $widthFromMedia) {
    @supports (#{$property}: $propertyValue) {
      #{$property}: $propertyValue;
    }
    @supports not (#{$property}: $propertyValue) {
      #{$property}: calc(
        #{rem($minSize)} + #{$addSize} * (100vw - #{rem($widthTo)}) / #{math.div(
            $widthFrom,
            16
          ) - math.div($widthTo, 16)}
      );
    }
  }
  @if $keepSize != 1 and $keepSize != 2 {
    @media (max-width: $widthToMedia) {
      #{$property}: rem($minSize);
    }
  }
}

@import "variables";

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  //outline: none;
  outline-color: var(--accent);
}

a:focus,
a:active {
  //outline: none;
  outline-color: var(--accent);
}

html,
body {
  height: 100%;
  min-width: $min-width + px;
}

body {
  font-size: var(--font-size);
  color: var(--text-color);
  line-height: 1;
  font-family: var(--font-family);
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: var(--font-family);
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

@use "reset";
@use "global";
@use "fonts";
@use "mixins";

#root {
  height: 100%;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > main {
    padding-top: 40px;
    padding-bottom: 80px;
    flex: 1 1 auto;
    background-color: var(--bg-color);
    @media (max-width: mixins.em(850)) {
      padding-top: 20px;
    }
  }
  > * {
    min-width: 0;
  }
}

body iframe {
  display: none;
}

.no-padding {
  .simplebar-track {
    right: 2px !important;
  }
}

.simplebar-track {
  right: -15px !important;
  width: 4px !important;
  background-color: #f1f1f1;
  margin: 2px 0;
  border-radius: 10px;
}

.simplebar-scrollbar::before {
  width: 4px !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  background: rgba(52, 81, 123, 0.3) !important;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 14px;
  &:not(:first-child) {
    margin-top: 10px;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

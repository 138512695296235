@import "src/assets/styles/variables";

.Footer {
  padding: 25px 0;
  background-color: #e6eff6;
  color: #656973;
}

.FooterBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 30px;
  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.copy {
}

.menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -15px;
  .link {
    padding: 5px 15px;
    font-weight: 500;
    transition: color 0.2s ease 0s;
    @media (any-hover: hover) {
      &:hover {
        color: var(--main-dark);
      }
    }
  }
}

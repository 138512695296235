@import "src/assets/styles/variables";

.loginButton {
  @media (min-width: $mobile) {
    flex: 0 0 145px;
    width: 145px;
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: $mobile) {
    border-top: 1px solid rgba(52, 81, 123, 0.1);
    text-align: center;
    font-size: 19px;
    padding: 30px 0 !important;
    svg {
      font-size: 19px;
      flex: 0 0 19px;
    }
  }
}

.dropdown {
  position: relative;
  &:hover {
    .dropdownBody {
      opacity: 1;
      visibility: visible;
    }
    .dropdownTitle {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  .dropdownTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    color: #656973;
    font-weight: 500;
    line-height: 1.5;
    cursor: pointer;
    transition: color 0.2s ease 0s;
    &:hover {
      color: var(--main-dark);
    }
    svg {
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
      font-size: 13px;
      transition: transform 0.2s ease 0s;
      margin-top: -3px;
    }
  }
  .dropdownBody {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease 0s;

    @media (max-width: $mobile) {
      top: -220%;
      left: 50%;
    }
  }
  .dropdownList {
    background: #fff;
    box-shadow: 0 5px 25px 0 rgba(22, 49, 61, 0.1);
    border-radius: 10px;
    margin-top: 5px;
    width: 100%;
    min-width: 165px;
    @media (max-width: $mobile) {
      min-width: 200px;
    }
  }
  .dropdownButton {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 8px;
    color: #656973;
    font-weight: 500;
    line-height: 1.5;
    padding: 10px 12px;
    transition: color 0.2s ease 0s;
    svg {
      width: 20px;
      flex: 0 0 20px;
      font-size: 18px;
    }
    @media (any-hover: hover) {
      &:hover {
        color: var(--main-dark);
      }
    }
  }
}

@use "sass:math";
@import "variables";

.container {
  max-width: $container-width + px;
  width: 100%;
  margin: 0 auto;
  @include adaptiveValue("padding-left", math.div($container-padding, 2), 10);
  @include adaptiveValue("padding-right", math.div($container-padding, 2), 10);
}

.coming-soon {
  &[disabled] {
    position: relative;
    pointer-events: none;
    opacity: 1 !important;
    &::after {
      content: "soon";
      position: absolute;
      background: var(--accent-gradient);
      border-radius: 5px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ffffff;
      height: 18px;
      bottom: 0;
      right: 0;
      transform: translate(50%, -15px);
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--bg-color);
      opacity: 0.7;
    }
    @media (max-width: $mobile) {
      &::after {
        bottom: unset;
        top: 0;
        transform: translate(-50%, 50%);
      }
      &::before {
        background-color: #fff;
      }
    }
  }
}
